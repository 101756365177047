import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
// import Vimeo from "@u-wave/react-vimeo"
import ReactPlayer from "react-player"
import styled from "styled-components"

import MuteSvg from "./../images/graphics/mute.svg"

const Wrapper = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  ${props =>
    props.overlayAmount &&
    `
        &:after {
            content: '';
            position: absolute;
            
            top: 0;
            left: 0;

            z-index: 1;
            
            width: 100%;
            height: 100%;

            background-color: rgba(23, 21, 21, ${props.overlayAmount / 10});
        }
    `}
`

const StyledVimeo = styled(ReactPlayer)`
  pointer-events: none;

  iframe {
    position: absolute;

    top: 0;
    left: 0;

    aspect-ratio: 426 / 240;

    background-color: #171515 !important;

    width: 100% !important;
    height: auto !important;

    max-height: 100vh;
    max-width: 100vw;
  }
`

const Overlay = styled.div`
    position: absolute;
    
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;

    background-color: #fffbf7;

    opacity: ${props => (props.visible == "true" ? 1 : 0)};

    transition: opacity: 0.25s ${props => props.theme.transitions.cubic};
`

const ButtonMute = styled(motion.button)`
  display: none;

  position: absolute;

  right: 6rem;
  bottom: 6rem;

  cursor: pointer;

  ${props => props.theme.above.desktop`
        display: block;
    `}
`

const MuteIcon = styled(MuteSvg)`
  width: 3rem;
  height: 3rem;

  ${props =>
    props.muted
      ? `
        .volume {
            display: none;
        }
        
        .mute {
            display: block;
        }
    `
      : `
        .volume {
            display: block;
        }

        .mute {
            display: none;
        }
    `}
`

const BackgroundVideo = ({ id, overlayAmount, setVideoPlaying }) => {
  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(false)
  const [muted, setMuted] = useState(true)

  const [videoRef, videoInView] = useInView({
    threshold: 0.5,
  })

  const togglePlay = () => {
    setPlaying(true)
    setVideoPlaying(true)
  }

  // console.log(`https://vimeo.com/${id}`)

  return (
    <Wrapper ref={videoRef} overlayAmount={overlayAmount}>
      <StyledVimeo
        url={`https://vimeo.com/${id}`}
        playing={true}
        mute={muted ? "true" : "false"}
        volume={muted ? 0 : 1}
        playsinline={true}
        loop={true}
        width="100%"
        height="100%"
        onStart={() => {
          togglePlay()
          setReady(true)
        }}
      />
      <Overlay visible={playing ? "false" : "true"} />
      <ButtonMute
        onClick={() => setMuted(!muted)}
        animate={videoInView ? "after" : "before"}
        initial="before"
        variants={{
          before: {
            opacity: 0,
            scale: 0.5,
          },
          after: {
            opacity: 1,
            scale: 1,
          },
        }}
        transition={{
          delay: 5.5,
          damping: 100,
          duration: 0.35,
          ease: "circOut",
        }}
      >
        <MuteIcon muted={muted} />
      </ButtonMute>
    </Wrapper>
  )
}

export default BackgroundVideo
