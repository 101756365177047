import React, { useState } from "react"
import styled from "styled-components"
import Div100vh from "react-div-100vh"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Grid from "./Grid"
import Title from "./Title"
import ImagesCarousel from "./Carousels/ImagesCarousel"
import TextRenderer from "./TextRenderer"
import BackgroundVideo from "./BackgroundVideo"

const StyledContainer = styled.section`
  position: relative;

  width: 100%;
  height: auto;

  max-height: 100vh;

  aspect-ratio: 426 / 240;

  overflow: hidden;

  background: ${props => props.theme.colors.greyDark};
`

const StyledImagesCarousel = styled(ImagesCarousel)`
    position: absolute;
    
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    ${props =>
      props.overlayAmount &&
      `
        &:after {
            content: '';
            position: absolute;
            
            top: 0;
            left: 0;

            z-index: 1;
            
            width: 100%;
            height: 100%;

            background-color: rgba(23, 21, 21, ${props.overlayAmount / 10});
        }
    `}

    ${props => props.theme.below.desktop`
        margin-top: 0;
    `}

    ${props => props.theme.above.desktop`
        margin-top: 0;

        ${props.type == "fade" &&
          `
            max-width: none;
            margin: 0;
        `}
    `}
`

const StyledContent = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  z-index: 1;

  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
`

const StyledTitle = styled(Title)`
  ${props => props.theme.below.desktop`
        display: none;

        h1 {
            margin-top: 0;
            margin-bottom: 0;
        }
    `}

  ${props => props.theme.above.desktop`
        display: block;

        h1 {
            margin-top: 5rem;
            
            font-size: 10rem;
            line-height: 10rem;
        }
    `}
`

const MobileTitle = styled.h1`
  padding: 0 3rem;

  text-align: center;

  ${props => props.theme.above.desktop`
        display: none;
    `}
`

const StyledGrid = styled(Grid)`
  ${props => props.theme.below.desktop`
        display: none;
    `}
`

const DownloadWrapper = styled.div`
  position: absolute;

  left: 50%;
  bottom: 4rem;

  transform: translateX(-50%);
  z-index: 2;

  overflow: hidden;

  cursor: pointer;
`

const DownloadLink = styled(motion.a)`
  display: block;

  position: relative;

  background-color: #fffbf9;
  padding: 1.5rem 2rem;

  border-radius: 8rem;

  &:hover {
    transform: scale(0.95) !important;
  }

  transition: transform 0.2s ${props => props.theme.transitions.cubic};

  ${props => props.theme.above.desktop`
        padding: 3rem 3.5rem;
    `}
`

const DownloadLabel = styled.span`
  display: inline-block;
  vertical-align: middle;

  position: relative;

  top: 0.1rem;

  font-family: ${props => props.theme.fontFamily.estiRegular};
  font-size: 1.2rem;
  line-height: 1.2rem;

  &.label {
    color: ${props => props.theme.colors.greyDark};
  }

  ${props => props.theme.above.desktop`
        font-size: 2rem;
        line-height: 2rem;
    `}
`

const HeroBanner = ({ data, lang, layout }) => {
  const [ref, inView] = useInView({
    threshold: 0.25,
  })

  const [videoPlaying, setVideoPlaying] = useState(false)

  return (
    <div>
      <StyledContainer ref={ref}>
        {data.type == "Carousel" && (
          <StyledImagesCarousel
            overlayAmount={data.overlayAmount}
            type="fade"
            data={data}
          />
        )}
        {data.type == "Video" && (
          <BackgroundVideo
            id={data.vimeoId}
            overlayAmount={data.overlayAmount}
            setVideoPlaying={() => setVideoPlaying(!videoPlaying)}
          />
        )}
        {data.downloadLink && (
          <DownloadWrapper>
            <DownloadLink
              href={data.downloadLink.file.url}
              target="_blank"
              className="link"
              animate={true ? "after" : "before"}
              initial={{
                y: 100,
              }}
              variants={{
                before: {
                  y: 200,
                },
                after: {
                  y: 0,
                },
              }}
              transition={{
                delay: 5,
                damping: 100,
                duration: 0.35,
                ease: "circOut",
              }}
            >
              <DownloadLabel className="label">
                {data.downloadTitle}
              </DownloadLabel>
            </DownloadLink>
          </DownloadWrapper>
        )}
        {/* <StyledContent>
                    {data.contentTitle && (
                        <StyledTitle
                            lang={lang}
                            data={data.contentTitle}
                        />
                    )}
                    {data.contentTitle && (
                        <MobileTitle>{data.contentTitle.title}</MobileTitle>
                    )}
                    {data.previewItems && (
                        <StyledGrid type='row'>
                            <Grid type='column' columns={4} offset={2}>
                                <TextRenderer
                                    lang={lang}
                                    data={data.previewItems[0].content}
                                />
                            </Grid>
                            <Grid type='column' columns={4}>
                                <TextRenderer
                                    lang={lang}
                                    data={data.previewItems[1].content}
                                />
                            </Grid>
                        </StyledGrid>
                    )}
                </StyledContent> */}
      </StyledContainer>
    </div>
  )
}

export default HeroBanner
